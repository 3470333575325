export const PATH_AUTH_ROOT = '/';
export const PATH_AUTH_SIGN_OUT = '/signout';
export const PATH_AUTH_REGISTRATION = '/register';
export const PATH_AUTH_SIGN_UP_EMAIL_CALLBACK_V2 = '/signup-email-callback-v2';
export const PATH_AUTH_ADD_USER_EMAIL_CALLBACK = '/add-user-email-callback';
export const PATH_AUTH_SIGN_UP_SOCIAL_MEDIA_CALLBACK = '/signup-social-media-callback';
export const PATH_AUTH_SIGN_UP_TWO_FA = '/signup-twofa';
export const PATH_AUTH_SIGN_IN_SOCIAL_MEDIA_CALLBACK = '/signin-social-media-callback';
export const PATH_AUTH_SIGN_IN_TWO_FA = '/signin-twofa';
export const PATH_AUTH_RESET_PASSWORD = '/reset-password';
export const PATH_AUTH_RESET_PASSWORD_CALLBACK = '/reset-password-callback';
export const PATH_AUTH_CHANGE_USER_NAME = '/user-name-change';
export const PATH_AUTH_CHANGE_USER_NAME_CALLBACK = '/user-name-change-callback';
export const PATH_API_DOCUMENTATION = '/api-documentation';
export const PATH_AUTH_SIGN_UP_PARTNER = '/register-partner';
export const PATH_AUTH_SIGN_UP_PARTNER_CUSTOMER = '/signup';
