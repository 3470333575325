import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import {
  signoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authSocialMediaUserSaga,
  authSocialMediaCallbackSaga,
  authTwoFASaga,
  registerTwoFASaga,
  resetPasswordSaga,
  resetPasswordEmailConfirmationSaga,
  saveResetPasswordSaga,
  changeUserNameSaga,
  changeUserNameEmailConfirmationSaga,
  validateReferralTokenSaga
} from './auth';

import {
  signUpUserSaga,
  signUpEmailResendSaga,
  emailConfirmationSaga,
  registerSaga,
  verifyVatNumberSaga,
  verifyCompanyNameSaga,
  addCompanySaga,
  signUpSocialMediaUserSaga,
  signUpSocialMediaCallbackSaga,
  checkDisposableEmailSaga,
  addUserEmailConfirmationSaga,
  addUserSaga
} from './signUp';

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_INITIATE_SIGNOUT, signoutSaga),
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_SOCIAL_MEDIA_USER, authSocialMediaUserSaga),
    takeEvery(actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK, authSocialMediaCallbackSaga),
    takeEvery(actionTypes.AUTH_TWOFA, authTwoFASaga),
    takeEvery(actionTypes.REGISTER_TWOFA, registerTwoFASaga),
    takeEvery(actionTypes.RESET_PASSWORD, resetPasswordSaga),
    takeEvery(actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION, resetPasswordEmailConfirmationSaga),
    takeEvery(actionTypes.VALIDATE_REFERRAL_TOKEN, validateReferralTokenSaga),
    takeEvery(actionTypes.SAVE_RESET_PASSWORD, saveResetPasswordSaga),
    takeEvery(actionTypes.CHANGE_USER_NAME, changeUserNameSaga),
    takeEvery(actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION, changeUserNameEmailConfirmationSaga)
  ]);
}

export function* watchSignUp() {
  yield all([
    takeEvery(actionTypes.SIGN_UP_USER, signUpUserSaga),
    takeEvery(actionTypes.SIGN_UP_RESEND_USER, signUpEmailResendSaga),
    takeEvery(actionTypes.EMAIL_CONFIRMATION, emailConfirmationSaga),
    takeEvery(actionTypes.REGISTER_USER, registerSaga),
    takeEvery(actionTypes.VERIFY_VAT_NUMBER, verifyVatNumberSaga),
    takeEvery(actionTypes.VERIFY_COMPANY_NAME, verifyCompanyNameSaga),
    takeEvery(actionTypes.ADD_COMPANY, addCompanySaga),
    takeEvery(actionTypes.SIGN_UP_SOCIAL_MEDIA_USER, signUpSocialMediaUserSaga),
    takeEvery(actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK, signUpSocialMediaCallbackSaga),
    takeEvery(actionTypes.CHECK_DISPOSABLE_EMAIL, checkDisposableEmailSaga),
    takeEvery(actionTypes.ADD_USER_EMAIL_CONFIRMATION, addUserEmailConfirmationSaga),
    takeEvery(actionTypes.ADD_USER, addUserSaga)
  ]);
}
