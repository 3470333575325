import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../Shared/helpers';

const initialState = {
  token: null,
  userId: null,
  role: null,
  error: null,
  loading: false,
  authRedirectPath: null,
  authSessionId: null,
  tempToken: null,
  authRedirectUrl: null,
  message: null,
  authCode: null,
  rememberMe: false,
  tfa: false,
  language: null
};

const authStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    rememberMe: false
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    role: action.role,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: action.authCode,
    rememberMe: action.rememberMe,
    language: action.language
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRedirectPath: null,
    authSessionId: null,
    tempToken: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authSignout = (state) => {
  return updateObject(state, {
    token: null,
    userId: null,
    authCode: null,
    rememberMe: false
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    authRedirectPath: action.path,
    authSessionId: action.authSessionId,
    tempToken: action.tempToken,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: action.rememberMe
  });
};

const authSocialMediaStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authSocialMediaSuccess = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authSocialMediaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authSocialMediaCallbackStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authSocialMediaCallbackSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    role: action.role,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: action.authCode,
    rememberMe: false
  });
};

const authSocialMediaCallbackFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const authTwoFAStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    rememberMe: false
  });
};

const authTwoFASuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    role: action.role,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: action.authCode,
    rememberMe: false,
    language: action.language
  });
};

const authTwoFAFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const registerTwoFAStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    rememberMe: false
  });
};

const registerTwoFASuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    role: action.role,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: action.authCode,
    rememberMe: false
  });
};

const registerTwoFAFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const setAuthRedirectUrl = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    authRedirectUrl: action.url,
    message: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordEmailConfirmationStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordEmailConfirmationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    message: action.message,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const resetPasswordEmailConfirmationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const saveResetPasswordStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const saveResetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const saveResetPasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameEmailConfirmationStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameEmailConfirmationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    message: action.message,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const changeUserNameEmailConfirmationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const validateReferralTokenStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const validateReferralTokenSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const validateReferralTokenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const validateReferralTokenSuccessCallback = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    message: null,
    authRedirectPath: null,
    authRedirectUrl: null,
    authCode: null,
    rememberMe: false
  });
};

const tfaSuccess = (state) => {
  return updateObject(state, {
    loading: false,
    tfa: true
  });
};

const tfaReset = (state) => {
  return updateObject(state, {
    tfa: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_SIGNOUT: return authSignout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_START: return authSocialMediaStart(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_SUCCESS: return authSocialMediaSuccess(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_FAIL: return authSocialMediaFail(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_START:
      return authSocialMediaCallbackStart(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_SUCCESS:
      return authSocialMediaCallbackSuccess(state, action);
    case actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_FAIL:
      return authSocialMediaCallbackFail(state, action);
    case actionTypes.AUTH_TWOFA_START: return authTwoFAStart(state, action);
    case actionTypes.AUTH_TWOFA_SUCCESS: return authTwoFASuccess(state, action);
    case actionTypes.AUTH_TWO_FA_SUCCESS: return tfaSuccess(state, action);
    case actionTypes.AUTH_TWO_FA_RESET: return tfaReset(state, action);
    case actionTypes.AUTH_TWOFA_FAIL: return authTwoFAFail(state, action);
    case actionTypes.REGISTER_TWOFA_START: return registerTwoFAStart(state, action);
    case actionTypes.REGISTER_TWOFA_SUCCESS: return registerTwoFASuccess(state, action);
    case actionTypes.REGISTER_TWOFA_FAIL: return registerTwoFAFail(state, action);
    case actionTypes.SET_AUTH_REDIRECT_URL: return setAuthRedirectUrl(state, action);
    case actionTypes.RESET_PASSWORD_START: return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_START:
      return resetPasswordEmailConfirmationStart(state, action);
    case actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_SUCCESS:
      return resetPasswordEmailConfirmationSuccess(state, action);
    case actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_FAIL:
      return resetPasswordEmailConfirmationFail(state, action);
    case actionTypes.SAVE_RESET_PASSWORD_START: return saveResetPasswordStart(state, action);
    case actionTypes.SAVE_RESET_PASSWORD_SUCCESS: return saveResetPasswordSuccess(state, action);
    case actionTypes.SAVE_RESET_PASSWORD_FAIL: return saveResetPasswordFail(state, action);
    case actionTypes.CHANGE_USER_NAME_START: return changeUserNameStart(state, action);
    case actionTypes.CHANGE_USER_NAME_SUCCESS: return changeUserNameSuccess(state, action);
    case actionTypes.CHANGE_USER_NAME_FAIL: return changeUserNameFail(state, action);
    case actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_START:
      return changeUserNameEmailConfirmationStart(state, action);
    case actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_SUCCESS:
      return changeUserNameEmailConfirmationSuccess(state, action);
    case actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_FAIL:
      return changeUserNameEmailConfirmationFail(state, action);
    case actionTypes.VALIDATE_REFERRAL_TOKEN_START: return validateReferralTokenStart(state, action);
    case actionTypes.VALIDATE_REFERRAL_TOKEN_SUCCESS: return validateReferralTokenSuccess(state, action);
    case actionTypes.VALIDATE_REFERRAL_TOKEN_FAIL: return validateReferralTokenFail(state, action);
    case actionTypes.VALIDATE_REFERRAL_TOKEN_SUCCESS_CALLBACK: return validateReferralTokenSuccessCallback(state, action);
    default:
      return state;
  }
};

export default reducer;
