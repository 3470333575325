import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Redirect, Switch
} from 'react-router-dom';

import asyncComponent from './Shared/components/AsyncComponent/AsyncComponent';
// import ImageGallery from './Auth/components/ImageGallery/ImageGalley';
import * as paths from './Shared/PathNames';
import { USER_ROLES } from './Shared/config';
import { getParameterValues } from './Shared/helpers';
import BrandContext from './Contexts';

// import SignUpDetailsSocialMedia from './Auth/containers/SignUpDetailsSocialMedia/SignUpDetailsSocialMedia';
// import SignInCallback from './Auth/containers/SignInCallback/SignInCallback';
// import APIDocumentation from './Shared/containers/Documentation/Documentation';

const AsyncAuthSignIn = asyncComponent(() => import('./Auth/containers/SignIn/SignIn'));
const AsyncAuthResetPassword = asyncComponent(() => import('./Auth/containers/ResetPassword/ResetPassword'));
const SignUpV2 = asyncComponent(() => import('./Auth/containers/SignUpV2/SignUp'));
const SignUpDetailsV2 = asyncComponent(() => import('./Auth/containers/SignUpConf/SignUpConf'));
const AddUser = asyncComponent(() => import('./Auth/containers/AddUser/AddUser'));
const SignUpTwoFA = asyncComponent(() => import('./Auth/containers/SignUpTwoFA/SignUpTwoFA'));
const SignInTwoFA = asyncComponent(() => import('./Auth/containers/SignInTwoFA/SignInTwoFA'));
const ResetPasswordCallback = asyncComponent(() => import('./Auth/containers/ResetPasswordCallback/ResetPasswordCallback'));
const SignUpPartner = asyncComponent(() => import('./Auth/containers/SignUpPartner/SignUpPartner'));
const SignUpPartnerCustomer = asyncComponent(() => import('./Auth/containers/SignUpPartnerCustomer/SignUpPartnerCustomer'));

class Routes extends PureComponent {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    const { IS_BRAND_PROCYS } = this.context;
    const {
      isAuthenticated, isSuperUser, token, role, userRef, authCode, rememberMe, language
    } = this.props;

    let url = null;
    const redirectionValues = {
      token,
      authCode,
      rememberMe,
      userRef,
      role,
      markettingPromotion: '',
      language
    };

    const FRONT_END_URL = window.config.frontend.main_panel;
    const ADMIN_FRONT_END_URL = window.config.frontend.admin_panel;

    if (isAuthenticated && isSuperUser) {
      url = `${ADMIN_FRONT_END_URL}${token}&${role}&${userRef}&${rememberMe}`;
      window.location.replace(url);
      return null;
    }

    if (isAuthenticated && !isSuperUser) {
      url = FRONT_END_URL;
      // NOTE: Redirection should depend on the TARGET application's authentication system..
      const targetUrl = `${FRONT_END_URL}login` || '';
      const targetParams = localStorage.getItem('LOGIN_PANEL_APPLICATION_PARAMETERS') || '';
      let query = '?';
      if (targetUrl && targetParams) {
        const params = targetParams.split(',');
        if (params.length > 0) {
          for (let i = 0; i < params.length; i += 1) {
            query = `${query}${i !== 0 ? '&' : ''}${params[i].trim()}=${getParameterValues(params[i].trim(), redirectionValues)}`;
          }
          window.location.replace(targetUrl + query);
          return true;
        }
      }
      window.location.replace(url);
      return null;
    }

    return (
      <React.Fragment key="switch-routes">
        <Switch>
          <Route exact path={paths.PATH_AUTH_ROOT} component={() => <AsyncAuthSignIn />} />
          {/* { IS_BRAND_PROCYS && <Route path={paths.PATH_API_DOCUMENTATION} component={APIDocumentation} /> } */}
          {/* <Hidden xsDown>
              <ImageGallery image={image} />
            </Hidden> */}
          { IS_BRAND_PROCYS && <Route path={paths.PATH_AUTH_REGISTRATION} component={SignUpV2} />}
          <Route path={paths.PATH_AUTH_SIGN_UP_EMAIL_CALLBACK_V2} component={SignUpDetailsV2} />
          <Route path={paths.PATH_AUTH_ADD_USER_EMAIL_CALLBACK} component={AddUser} />
          {/* <Route path={paths.PATH_AUTH_SIGN_UP_SOCIAL_MEDIA_CALLBACK} component={SignUpDetailsSocialMedia} /> */}
          <Route path={paths.PATH_AUTH_SIGN_UP_TWO_FA} component={SignUpTwoFA} />
          {/* <Route path={paths.PATH_AUTH_SIGN_IN_SOCIAL_MEDIA_CALLBACK} component={SignInCallback} /> */}
          <Route path={paths.PATH_AUTH_SIGN_IN_TWO_FA} component={SignInTwoFA} />
          <Route path={paths.PATH_AUTH_RESET_PASSWORD} component={AsyncAuthResetPassword} />
          <Route path={paths.PATH_AUTH_RESET_PASSWORD_CALLBACK} component={ResetPasswordCallback} />
          <Route path={paths.PATH_AUTH_SIGN_UP_PARTNER} component={SignUpPartner} />
          <Route path={paths.PATH_AUTH_SIGN_UP_PARTNER_CUSTOMER} component={SignUpPartnerCustomer} />
          <Redirect to={paths.PATH_AUTH_ROOT} />
        </Switch>
      </React.Fragment>
    );
  }
}

Routes.contextType = BrandContext;

Routes.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  token: PropTypes.string,
  role: PropTypes.string,
  userRef: PropTypes.string,
  authCode: PropTypes.string,
  rememberMe: PropTypes.bool,
  language: PropTypes.string
};

Routes.defaultProps = {
  isAuthenticated: false,
  isSuperUser: false,
  token: '',
  role: '',
  userRef: '',
  authCode: '',
  rememberMe: false,
  language: ''
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    isSuperUser: state.auth.role === USER_ROLES.super_user,
    token: state.auth.token,
    role: state.auth.role,
    userRef: state.auth.userId,
    authCode: state.auth.authCode,
    rememberMe: state.auth.rememberMe,
    language: state.auth.language
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
