import { put } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';

import * as actions from '../actions/news';
import API from '../../../config';
import { appendContactSupport, axiosHeaders } from '../../../Shared/helpers';

const t = i18next.t.bind(i18next);

function* fetchNewsSaga(action) {
  yield put(actions.fetchNewsStart());
  try {
    const url = `${window.config.backend.news_service}${API.news}/company/${action.company}/feed/${action.feed}`;
    const response = yield axios.get(url, axiosHeaders(action.token));

    yield put(actions.fetchNewsSuccess(response.data.data));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }

    yield put(actions.fetchNewsFail(errorMessage));
  }
}

export default fetchNewsSaga;
