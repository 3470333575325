import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

/**
 * @type {{open_provider: string, google: string, facebook: string}}
 */
export const USER_TYPES = {
  open_provider: 'op',
  google: 'gl',
  facebook: 'fb'
};

/**
 * @type {{super_user: string, user: string}}
 */
export const USER_ROLES = {
  super_user: 'super',
  user: 'user'
};

export const FORM_PROPS = {
  password_label: 'PASSWORD',
  password_min_length: 5,
  form_type_user_details_name: 'USER_DETAILS'
};

/**
 * @type {{language_file: string}}
 */
export const URL = {
  language_file:
    'https://docs.google.com/spreadsheets/d/1v75Ef0nmuXoeZNPN8kjQmRZ5aQVuE3u9BQfBfNwPAx0/edit?ts=5d8c569a#gid=0er'
};

export const ERRORS = {
  email_empty: 'EMPTY_EMAIL',
  email_format_error: 'INVALID_EMAIL',
  dynamic_form_fields_empty_error: 'PLEASE_FILL_ALL_FIELDS',
  disposable_email_used: 'DISPOSABLE_EMAIL_NOT_ALLOWED',
  password_empty: 'EMPTY_PASSWORD',
  password_min_length: 'PASSWORD_TOO_SHORT',
  password_max_length: 'PASSWORD_MAX_LENGTH',
  password_uppercase: 'PASSWORD_REQUIRE_UPPERCASE',
  password_lowercase: 'PASSWORD_REQUIRE_LOWERCASE',
  password_digit: 'PASSWORD_REQUIRE_DIGIT',
  password_special_character: 'PASSWORD_REQUIRE_SPECIAL_CHAR',
  password_encrypt_error: 'PASSWORD_ENCRYPTION_FAILED',
  password_mismatch: 'PASSWORD_MISMATCH',
  country_empty: 'COUNTRY_EMPTY',
  lang_empty: 'LANGUAGE_EMPTY',
  firstname_empty: 'FIRSTNAME_EMPTY',
  lastname_empty: 'LASTNAME_EMPTY',
  recaptcha: 'RECAPTCHA_ERROR'
};

export const QUESTION_POPOVER_TWO_FA = (
  <Fragment key="questionPopoverContent">
    <Typography variant="subtitle2" color="textPrimary">
      1.
      {' '}
      <Trans i18nKey="INSTALL_GOOGLE_AUTHENTICATOR" />
    </Typography>
    <Typography variant="subtitle2" color="textPrimary">
      2.
      {' '}
      <Trans i18nKey="OPEN_GOOGLE_AUTHENTICATOR" />
    </Typography>
    <Typography variant="subtitle2" color="textPrimary">
      3.
      {' '}
      <Trans i18nKey="SELECT_BARCODE_OR_MANUAL" />
    </Typography>
    <Typography variant="subtitle2" color="textPrimary">
      4.
      {' '}
      <Trans i18nKey="SCAN_QR_CODE" />
    </Typography>
    <Typography variant="subtitle2" color="textPrimary">
      5.
      {' '}
      <Trans i18nKey="ENTER_SECRET_KEY" />
    </Typography>
    <Typography variant="subtitle2" color="textPrimary">
      6.
      {' '}
      <Trans i18nKey="ENTER_CODE" />
    </Typography>
  </Fragment>
);

export const PASSWORD_SALT = '7XPLXeaf4qXwTVjE'; // some random string

export const RECAPTCHA_IV2_SITE_KEY = '6LeS0qQZAAAAAObsw133ACg5COLGY8lzmxsLm8hk';

export const RECAPTCHA_MAX_LOGIN_ATTEMPTS = 3;

export const CONDITIONS_STR = {
  TERMS: 'TERMS',
  POLICY: 'POLICY',
  PROCYS: 'PROCYS'
};

export const EUCountries = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
  'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
];
