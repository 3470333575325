import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId, role, authCode, rememberMe, language) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId,
    role,
    authCode,
    rememberMe,
    language
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error
  };
};

export const signout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_SIGNOUT
  };
};

export const signoutSucceed = () => {
  return {
    type: actionTypes.AUTH_SIGNOUT
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime
  };
};

export const auth = (email, password, userType, rememberMe, language) => {
  return {
    type: actionTypes.AUTH_USER,
    email,
    password,
    userType,
    rememberMe,
    language
  };
};

export const setAuthRedirectPath = (path, authSessionId, tempToken, rememberMe) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    tempToken,
    path,
    authSessionId,
    rememberMe
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
};

export const authSocialMediaStart = () => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_START
  };
};

export const authSocialMediaSuccess = (token, userId, role, authCode) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_SUCCESS,
    idToken: token,
    userId,
    role,
    authCode
  };
};

export const authSocialMediaFail = (error) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_FAIL,
    error
  };
};

export const authSocialMedia = (role) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_USER,
    role
  };
};

export const authSocialMediaCallbackStart = () => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_START
  };
};

export const authSocialMediaCallbackSuccess = (token, userId, role, authCode) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_SUCCESS,
    idToken: token,
    userId,
    role,
    authCode
  };
};

export const authSocialMediaCallbackFail = (error) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK_FAIL,
    error
  };
};

export const authSocialMediaCallback = (platform, params) => {
  return {
    type: actionTypes.AUTH_SOCIAL_MEDIA_CALLBACK,
    platform,
    params
  };
};

export const authTwoFAStart = () => {
  return {
    type: actionTypes.AUTH_TWOFA_START
  };
};

export const authTwoFASuccess = (message) => {
  return {
    type: actionTypes.AUTH_TWOFA_SUCCESS,
    message
  };
};

export const authTwoFAFail = (error) => {
  return {
    type: actionTypes.AUTH_TWOFA_FAIL,
    error
  };
};

export const authTwoFA = (token, otp, authSessionId, rememberMe, language) => {
  return {
    type: actionTypes.AUTH_TWOFA,
    token,
    otp,
    authSessionId,
    rememberMe,
    language
  };
};

export const registerTwoFAStart = () => {
  return {
    type: actionTypes.REGISTER_TWOFA_START
  };
};

export const registerTwoFASuccess = (message) => {
  return {
    type: actionTypes.REGISTER_TWOFA_SUCCESS,
    message
  };
};

export const twoFASuccess = () => {
  return {
    type: actionTypes.AUTH_TWO_FA_SUCCESS
  };
};

export const twoFAReset = () => {
  return {
    type: actionTypes.AUTH_TWO_FA_RESET
  };
};

export const registerTwoFAFail = (error) => {
  return {
    type: actionTypes.REGISTER_TWOFA_FAIL,
    error
  };
};

export const registerTwoFA = (token, otp, enable) => {
  return {
    type: actionTypes.REGISTER_TWOFA,
    token,
    otp,
    enable
  };
};

export const setAuthRedirectUrl = (url) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_URL,
    url
  };
};

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START
  };
};

export const resetPasswordSuccess = (message) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    message
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error
  };
};

export const resetPassword = (email) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    email
  };
};

export const resetPasswordEmailConfirmationStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_START
  };
};

export const resetPasswordEmailConfirmationSuccess = (message) => {
  return {
    type: actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_SUCCESS,
    message
  };
};

export const resetPasswordEmailConfirmationFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION_FAIL,
    error
  };
};

export const resetPasswordEmailConfirmation = (token) => {
  return {
    type: actionTypes.RESET_PASSWORD_EMAIL_CONFIRMATION,
    token
  };
};

export const saveResetPasswordStart = () => {
  return {
    type: actionTypes.SAVE_RESET_PASSWORD_START
  };
};

export const saveResetPasswordSuccess = (message) => {
  return {
    type: actionTypes.SAVE_RESET_PASSWORD_SUCCESS,
    message
  };
};

export const saveResetPasswordFail = (error) => {
  return {
    type: actionTypes.SAVE_RESET_PASSWORD_FAIL,
    error
  };
};

export const saveResetPassword = (token, password) => {
  return {
    type: actionTypes.SAVE_RESET_PASSWORD,
    token,
    password
  };
};

export const changeUserNameStart = () => {
  return {
    type: actionTypes.CHANGE_USER_NAME_START
  };
};

export const changeUserNameSuccess = (message) => {
  return {
    type: actionTypes.CHANGE_USER_NAME_SUCCESS,
    message
  };
};

export const changeUserNameFail = (error) => {
  return {
    type: actionTypes.CHANGE_USER_NAME_FAIL,
    error
  };
};

export const changeUserName = (token, email) => {
  return {
    type: actionTypes.CHANGE_USER_NAME,
    token,
    email
  };
};

export const changeUserNameEmailConfirmationStart = () => {
  return {
    type: actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_START
  };
};

export const changeUserNameEmailConfirmationSuccess = (message) => {
  return {
    type: actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_SUCCESS,
    message
  };
};

export const changeUserNameEmailConfirmationFail = (error) => {
  return {
    type: actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION_FAIL,
    error
  };
};

export const changeUserNameEmailConfirmation = (token) => {
  return {
    type: actionTypes.CHANGE_USER_NAME_EMAIL_CONFIRMATION,
    token
  };
};

export const validateReferralTokenStart = () => {
  return {
    type: actionTypes.VALIDATE_REFERRAL_TOKEN_START
  };
};

export const validateReferralTokenSuccess = (message) => {
  return {
    type: actionTypes.VALIDATE_REFERRAL_TOKEN_SUCCESS,
    message
  };
};

export const validateReferralTokenFail = (error) => {
  return {
    type: actionTypes.VALIDATE_REFERRAL_TOKEN_FAIL,
    error
  };
};

export const validateReferralToken = (token) => {
  return {
    type: actionTypes.VALIDATE_REFERRAL_TOKEN,
    token
  };
};

export const validateReferralTokenSuccessCallback = () => {
  return {
    type: actionTypes.VALIDATE_REFERRAL_TOKEN_SUCCESS_CALLBACK
  };
};
