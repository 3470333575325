import * as actionTypes from './actionTypes';

export const fetchNewsSuccess = (news) => {
  return {
    type: actionTypes.FETCH_NEWS_SUCCESS,
    news
  };
};

export const fetchNewsFail = (error) => {
  return {
    type: actionTypes.FETCH_NEWS_FAIL,
    error
  };
};

export const fetchNewsStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START
  };
};

export const fetchNews = (company, feed, token) => {
  return {
    type: actionTypes.FETCH_NEWS,
    company,
    feed,
    token
  };
};
