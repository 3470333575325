import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Spinner from '../Spinner/Spinner';

const style = {
  spinner: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center'
  }
};

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component
      });
    }

    render() {
      const { component } = this.state;
      const C = component;
      return C ? <C {...this.props} /> : (
        <Grid style={style.spinner}>
          <Spinner />
        </Grid>
      );
    }
  }

  return AsyncComponent;
}
