import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../Shared/helpers';

const initialState = {
  news: [],
  loading: false,
  error: null
};

const fetchNewsStart = (state) => {
  return updateObject(state, {
    loading: true
  });
};

const fetchNewsSuccess = (state, action) => {
  return updateObject(state, {
    news: action.news,
    loading: false,
    error: null
  });
};

const fetchNewsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    news: []
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEWS_START: return fetchNewsStart(state);
    case actionTypes.FETCH_NEWS_SUCCESS: return fetchNewsSuccess(state, action);
    case actionTypes.FETCH_NEWS_FAIL: return fetchNewsFail(state, action);
    default: return state;
  }
};

export default reducer;
