export const AUTH_USER = 'AUTH_USER';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_INITIATE_SIGNOUT = 'AUTH_INITIATE_SIGNOUT';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_AUTH_REDIRECT_URL = 'SET_AUTH_REDIRECT_URL';

export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const SIGN_UP_RESEND_USER = 'SIGN_UP_RESEND_USER';
export const SIGN_UP_RESEND_START = 'SIGN_UP_RESEND_START';
export const SIGN_UP_RESEND_SUCCESS = 'SIGN_UP_RESEND_SUCCESS';
export const SIGN_UP_RESEND_FAIL = 'SIGN_UP_RESEND_FAIL';

export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const EMAIL_CONFIRMATION_START = 'EMAIL_CONFIRMATION_START';
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_FAIL = 'EMAIL_CONFIRMATION_FAIL';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const ADD_USER_EMAIL_CONFIRMATION = 'ADD_USER_EMAIL_CONFIRMATION';
export const ADD_USER_EMAIL_CONFIRMATION_START = 'ADD_USER_EMAIL_CONFIRMATION_START';
export const ADD_USER_EMAIL_CONFIRMATION_SUCCESS = 'ADD_USER_EMAIL_CONFIRMATION_SUCCESS';
export const ADD_USER_EMAIL_CONFIRMATION_FAIL = 'ADD_USER_EMAIL_CONFIRMATION_FAIL';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const AUTH_SOCIAL_MEDIA_USER = 'AUTH_SOCIAL_MEDIA_USER';
export const AUTH_SOCIAL_MEDIA_START = 'AUTH_SOCIAL_MEDIA_START';
export const AUTH_SOCIAL_MEDIA_SUCCESS = 'AUTH_SOCIAL_MEDIA_SUCCESS';
export const AUTH_SOCIAL_MEDIA_FAIL = 'AUTH_SOCIAL_MEDIA_FAIL';

export const AUTH_SOCIAL_MEDIA_CALLBACK = 'AUTH_SOCIAL_MEDIA_CALLBACK';
export const AUTH_SOCIAL_MEDIA_CALLBACK_START = 'AUTH_SOCIAL_MEDIA_CALLBACK_START';
export const AUTH_SOCIAL_MEDIA_CALLBACK_SUCCESS = 'AUTH_SOCIAL_MEDIA_CALLBACK_SUCCESS';
export const AUTH_SOCIAL_MEDIA_CALLBACK_FAIL = 'AUTH_SOCIAL_MEDIA_CALLBACK_FAIL';

export const SIGN_UP_SOCIAL_MEDIA_USER = 'SIGN_UP_SOCIAL_MEDIA_USER';
export const SIGN_UP_SOCIAL_MEDIA_START = 'SIGN_UP_SOCIAL_MEDIA_START';
export const SIGN_UP_SOCIAL_MEDIA_SUCCESS = 'SIGN_UP_SOCIAL_MEDIA_SUCCESS';
export const SIGN_UP_SOCIAL_MEDIA_FAIL = 'SIGN_UP_SOCIAL_MEDIA_FAIL';
export const SET_SIGN_UP_REDIRECT_PATH = 'SET_SIGN_UP_REDIRECT_PATH';

export const SIGN_UP_SOCIAL_MEDIA_CALLBACK = 'SIGN_UP_SOCIAL_MEDIA_CALLBACK';
export const SIGN_UP_SOCIAL_MEDIA_CALLBACK_START = 'SIGN_UP_SOCIAL_MEDIA_CALLBACK_START';
export const SIGN_UP_SOCIAL_MEDIA_CALLBACK_SUCCESS = 'SIGN_UP_SOCIAL_MEDIA_CALLBACK_SUCCESS';
export const SIGN_UP_SOCIAL_MEDIA_CALLBACK_FAIL = 'SIGN_UP_SOCIAL_MEDIA_CALLBACK_FAIL';

export const AUTH_TWOFA = 'AUTH_TWOFA';
export const AUTH_TWOFA_START = 'AUTH_TWOFA_START';
export const AUTH_TWOFA_SUCCESS = 'AUTH_TWOFA_SUCCESS';
export const AUTH_TWOFA_FAIL = 'AUTH_TWOFA_FAIL';

export const AUTH_TWO_FA_SUCCESS = 'AUTH_TWO_FA_SUCCESS';
export const AUTH_TWO_FA_RESET = 'AUTH_TWO_FA_RESET';

export const REGISTER_TWOFA = 'REGISTER_TWOFA';
export const REGISTER_TWOFA_START = 'REGISTER_TWOFA_START';
export const REGISTER_TWOFA_SUCCESS = 'REGISTER_TWOFA_SUCCESS';
export const REGISTER_TWOFA_FAIL = 'REGISTER_TWOFA_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const RESET_PASSWORD_EMAIL_CONFIRMATION = 'RESET_PASSWORD_EMAIL_CONFIRMATION';
export const RESET_PASSWORD_EMAIL_CONFIRMATION_START = 'RESET_PASSWORD_EMAIL_CONFIRMATION_START';
export const RESET_PASSWORD_EMAIL_CONFIRMATION_SUCCESS = 'RESET_PASSWORD_EMAIL_CONFIRMATION_SUCCESS';
export const RESET_PASSWORD_EMAIL_CONFIRMATION_FAIL = 'RESET_PASSWORD_EMAIL_CONFIRMATION_FAIL';

export const SAVE_RESET_PASSWORD = 'SAVE_RESET_PASSWORD';
export const SAVE_RESET_PASSWORD_START = 'SAVE_RESET_PASSWORD_START';
export const SAVE_RESET_PASSWORD_SUCCESS = 'SAVE_RESET_PASSWORD_SUCCESS';
export const SAVE_RESET_PASSWORD_FAIL = 'SAVE_RESET_PASSWORD_FAIL';

export const CHANGE_USER_NAME = 'CHANGE_USER_NAME';
export const CHANGE_USER_NAME_START = 'CHANGE_USER_NAME_START';
export const CHANGE_USER_NAME_SUCCESS = 'CHANGE_USER_NAME_SUCCESS';
export const CHANGE_USER_NAME_FAIL = 'CHANGE_USER_NAME_FAIL';

export const CHANGE_USER_NAME_EMAIL_CONFIRMATION = 'CHANGE_USER_NAME_EMAIL_CONFIRMATION';
export const CHANGE_USER_NAME_EMAIL_CONFIRMATION_START = 'CHANGE_USER_NAME_EMAIL_CONFIRMATION_START';
export const CHANGE_USER_NAME_EMAIL_CONFIRMATION_SUCCESS = 'CHANGE_USER_NAME_EMAIL_CONFIRMATION_SUCCESS';
export const CHANGE_USER_NAME_EMAIL_CONFIRMATION_FAIL = 'CHANGE_USER_NAME_EMAIL_CONFIRMATION_FAIL';

export const CHECK_DISPOSABLE_EMAIL = 'CHECK_DISPOSABLE_EMAIL';
export const CHECK_DISPOSABLE_EMAIL_START = 'CHECK_DISPOSABLE_EMAIL_START';
export const CHECK_DISPOSABLE_EMAIL_SUCCESS = 'CHECK_DISPOSABLE_EMAIL_SUCCESS';
export const CHECK_DISPOSABLE_EMAIL_FAIL = 'CHECK_DISPOSABLE_EMAIL_FAIL';

export const VERIFY_VAT_NUMBER = 'VERIFY_VAT_NUMBER';
export const VERIFY_VAT_NUMBER_START = 'VERIFY_VAT_NUMBER_START';
export const VERIFY_VAT_NUMBER_SUCCESS = 'VERIFY_VAT_NUMBER_SUCCESS';
export const VERIFY_VAT_NUMBER_FAIL = 'VERIFY_VAT_NUMBER_FAIL';

export const VERIFY_COMPANY_NAME = 'VERIFY_COMPANY_NAME';
export const VERIFY_COMPANY_NAME_START = 'VERIFY_COMPANY_NAME_START';
export const VERIFY_COMPANY_NAME_SUCCESS = 'VERIFY_COMPANY_NAME_SUCCESS';
export const VERIFY_COMPANY_NAME_FAIL = 'VERIFY_COMPANY_NAME_FAIL';

export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_START = 'ADD_COMPANY_START';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';

export const VALIDATE_REFERRAL_TOKEN = 'VALIDATE_REFERRAL_TOKEN';
export const VALIDATE_REFERRAL_TOKEN_START = 'VALIDATE_REFERRAL_TOKEN_START';
export const VALIDATE_REFERRAL_TOKEN_SUCCESS = 'VALIDATE_REFERRAL_TOKEN_SUCCESS';
export const VALIDATE_REFERRAL_TOKEN_FAIL = 'VALIDATE_REFERRAL_TOKEN_FAIL';
export const VALIDATE_REFERRAL_TOKEN_SUCCESS_CALLBACK = 'VALIDATE_REFERRAL_TOKEN_SUCCESS_CALLBACK';
