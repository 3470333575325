export default {
  signin: 'v1/auth/signin',
  signUp: 'v1/auth/signup',
  registerV2: 'v1/auth/register',
  registerPartner: 'v1/auth/register-partner',
  signUpResend: 'v1/auth/signup/resend',
  emailConfirmation: 'v1/auth/email-confirmation',
  register: 'v1/auth/user-details',
  entityFields: 'v1/admin/entity-fields',
  socialMediaCallback: 'v1/oauth2',
  otpRegistration: 'v1/auth/2fa/totp',
  otpValidation: 'v1/auth/2fa/totp/validate',
  resetPassword: 'v1/auth/reset-password/init',
  resetPasswordEmailConfirmation: 'v1/auth/reset-password/email-confirmation',
  resetPasswordSave: 'v1/auth/reset-password/save',
  changeUserName: 'v1/auth/reconfigure-username',
  changeUserNameEmailConfirmation: 'v1/auth/reconfigure-username/email-confirmation',
  addUserEmailConfirmation: 'v1/external/users/email-confirmation',
  partnerTokenValidation: 'v1/signup/partner/token',
  userRegister: 'v1/external/users/register',
  news: 'v1/admin/news',
  checkDisposableEmail: 'https://disposable.debounce.io',
  getLanguages: 'v1/admin/languages',
  getLanguageJSON: 'v1/admin/languages/json',
  getCompanies: 'v1/admin/companies',
  addCompanies: 'v1/admin/companies',
  getApplications: 'v1/admin/applications',

  verifyVatNumber: 'v1/admin/companies/validate/vat',
  verifyCompanyName: 'v1/admin/companies/validate/name',
  registerPartnerCompany: 'v1/auth/register/partner/sub-company',

  feedbackLogs: 'v1/logs/feedback',

  brandLogo: 'v1/brands/logo/'
};
