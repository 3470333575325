import { put, delay } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';

import * as actions from '../actions/auth';
import API from '../../../config';
import { appendContactSupport, axiosHeaders } from '../../../Shared/helpers';
import * as paths from '../../../Shared/PathNames';

const t = i18next.t.bind(i18next);

export function* signoutSaga() {
  yield put(actions.signoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.signout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const authData = {
    email: action.email,
    password: action.password,
    type: action.userType,
    application: 'procys'
  };

  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.signin}`, authData);
    yield localStorage.setItem('token', response.data.data.accessToken);
    yield localStorage.setItem('authSessionId', response.data.data.authSessionID);
    yield localStorage.setItem('userName', action.email);
    if (!response.data.data.tfa || response.data.data.tfa === null) {
      yield put(actions.authSuccess(
        response.data.data.accessToken,
        response.data.data.userRef,
        response.data.data.role,
        response.data.data.authCode,
        action.rememberMe,
        action.language
      ));
      yield put(actions.checkAuthTimeout(response.data.data.expiresIn));
    } else {
      yield put(actions.twoFASuccess());
    }
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }

    yield put(actions.authFail(errorMessage));
  }
}

export function* authSocialMediaUserSaga(action) {
  yield put(actions.authSocialMediaStart());
  const authData = {
    type: action.role,
    application: 'procys'
  };
  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.signin}`, authData);
    yield put(actions.setAuthRedirectUrl(response.data.message));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }

    yield put(actions.authSocialMediaFail(errorMessage));
  }
}

export function* authSocialMediaCallbackSaga(action) {
  yield put(actions.authSocialMediaCallbackStart());
  try {
    const url = `${window.config.backend.login_service}${API.socialMediaCallback}/${action.platform}-signin-cb?${action.params}`;
    const response = yield axios.get(url);
    yield localStorage.setItem('token', response.data.data.accessToken);
    yield localStorage.setItem('authSessionId', response.data.data.authSessionID);
    if (!response.data.data.tfa) {
      yield put(actions.authSuccess(
        response.data.data.accessToken,
        response.data.data.userRef,
        response.data.data.role,
        response.data.data.authCode,
        false
      ));
      yield put(actions.checkAuthTimeout(response.data.data.expiresIn));
    } else {
      yield put(actions.setAuthRedirectPath(
        paths.PATH_AUTH_SIGN_IN_TWO_FA,
        response.data.data.authSessionID,
        response.data.data.accessToken
      ));
    }
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.authSocialMediaCallbackFail(errorMessage));
  }
}

export function* authTwoFASaga(action) {
  yield put(actions.authTwoFAStart());
  const data = {
    authSessionId: action.authSessionId,
    code: action.otp
  };
  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.otpValidation}`, data, axiosHeaders(action.token));
    yield put(actions.authSuccess(
      response.data.data.accessToken,
      response.data.data.userRef,
      response.data.data.role,
      response.data.data.authCode,
      action.rememberMe,
      action.language
    ));
    localStorage.setItem('authSessionId', null);
    yield put(actions.checkAuthTimeout(response.data.data.expiresIn));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.authTwoFAFail(errorMessage));
  }
}

export function* registerTwoFASaga(action) {
  yield put(actions.registerTwoFAStart());
  const data = {
    enable: action.enable,
    code: action.otp
  };
  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.otpRegistration}`, data, axiosHeaders(action.token));
    yield put(actions.authSuccess(
      response.data.data.accessToken,
      response.data.data.userRef,
      response.data.data.role,
      response.data.data.authCode,
      false,
      action.language
    ));
    yield put(actions.checkAuthTimeout(response.data.data.expiresIn));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.registerTwoFAFail(errorMessage));
  }
}

export function* resetPasswordSaga(action) {
  yield put(actions.resetPasswordStart());
  try {
    const url = `${window.config.backend.login_service}${API.resetPassword}/${action.email}`;
    const response = yield axios.get(url);
    yield put(actions.resetPasswordSuccess(response.data.message));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.resetPasswordFail(errorMessage));
  }
}

export function* resetPasswordEmailConfirmationSaga(action) {
  yield put(actions.resetPasswordEmailConfirmationStart());
  try {
    const url = `${window.config.backend.login_service}${API.resetPasswordEmailConfirmation}/${action.token}`;
    const response = yield axios.get(url);
    yield put(actions.resetPasswordEmailConfirmationSuccess(response.data.data.email));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.resetPasswordEmailConfirmationFail(errorMessage));
  }
}

export function* saveResetPasswordSaga(action) {
  yield put(actions.saveResetPasswordStart());
  const data = {
    newPassword: action.password
  };
  try {
    yield axios.post(`${window.config.backend.login_service}${API.resetPasswordSave}`, data, axiosHeaders(action.token));
    yield put(actions.setAuthRedirectUrl(paths.PATH_AUTH_ROOT));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }

    yield put(actions.saveResetPasswordFail(errorMessage));
  }
}

export function* changeUserNameSaga(action) {
  yield put(actions.changeUserNameStart());
  const data = {
    email: action.email
  };

  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.changeUserName}`, data, axiosHeaders(action.token));
    yield put(actions.changeUserNameSuccess(response.data.message));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.changeUserNameFail(errorMessage));
  }
}

export function* changeUserNameEmailConfirmationSaga(action) {
  yield put(actions.changeUserNameEmailConfirmationStart());
  try {
    const url = `${window.config.backend.login_service}${API.changeUserNameEmailConfirmation}/${action.token}`;
    yield axios.get(url);
    yield put(actions.setAuthRedirectUrl(paths.PATH_AUTH_ROOT));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.changeUserNameEmailConfirmationFail(errorMessage));
  }
}

export function* validateReferralTokenSaga(action) {
  yield put(actions.validateReferralTokenStart());
  if (!action.token || action.token.length < 16) {
    yield put(actions.validateReferralTokenFail('VALIDATE_REFERRAL_TOKEN_INVALID'));
    return;
  }
  try {
    const url = `${window.config.backend.login_service}${API.partnerTokenValidation}`;
    const response = yield axios.post(url, { token: action.token });
    if (response.data.success) {
      yield put(actions.validateReferralTokenSuccess(response.data.data.partner));
    } else {
      yield put(actions.validateReferralTokenFail(response.data.i18n));
    }
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.i18n;
    }
    yield put(actions.validateReferralTokenFail(errorMessage));
  }
}
