import React, { Fragment } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import useStyles from './style';

const Spinner = () => {
  const classes = useStyles();

  return (
    <Fragment key="Spinner">
      <Box className={classes.spinner}>
        <CircularProgress disableShrink style={{ color: '#03a9fa' }} />
      </Box>
    </Fragment>
  );
};

export default Spinner;
