import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import GoogleAnalytics from './Shared/components/GoogleAnalytics/GoogleAnalytics';
import Routes from './routes';
import BrandContext from './Contexts';

class App extends React.PureComponent {
  render() {
    const brandConfig = {
      BRAND_NAME: window.config.brand,
      BRAND_DOMAIN: window.config.brand_domain,
      IS_BRAND_PROCYS: window.config.brand === 'Procys',
      RECAPTCHA_SITE_KEY: window.config.recaptcha_site_key
    };

    return (
      <BrandContext.Provider value={brandConfig}>
        <React.Fragment key="routes">
          <GoogleAnalytics />
          <Routes />
        </React.Fragment>
      </BrandContext.Provider>
    );
  }
}

export default compose(withRouter)(App);
