import i18next from 'i18next';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkInputValidity = (name, value, rules) => {
  let isValid = true;
  let errorMessage = null;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
    errorMessage = {
      message: 'INPUT_EMPTY',
      values: { name, rule: null }
    };
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    errorMessage = {
      message: 'INPUT_NO_MIN_LENGTH',
      values: { name, rule: rules.minLength }
    };
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    errorMessage = {
      message: 'INPUT_NO_MAX_LENGTH',
      values: { name, rule: rules.maxLength }
    };
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
    errorMessage = {
      message: 'INPUT_ONLY_NUMERIC',
      values: { name, rule: null }
    };
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (isValid) { errorMessage = null; }

  return { isValid, errorMessage };
};

export const axiosHeaders = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  };
};

export const axiosHeadersForDelete = (token, data) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    data
  };
};

export const agencies = [
  {
    key: 1,
    value: 'company',
    label: 'AGENCY_FLOW_COMPANY_LABEL',
    desc: 'AGENCY_FLOW_COMPANY_DESCR'
  },
  {
    key: 2,
    value: 'agency',
    label: 'AGENCY_FLOW_AGENCY_LABEL',
    desc: 'AGENCY_FLOW_AGENCY_DESCR'
  },
  {
    key: 3,
    value: 'freelancer',
    label: 'AGENCY_FLOW_FREELANCER_LABEL',
    desc: 'AGENCY_FLOW_FREELANCER_DESCR'
  }
];

export const getCurrentDate = () => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const today = new Date();
  const year = today.getFullYear();
  const date = today.getDate();
  const month = months[today.getMonth()];

  return `${date}-${month}-${year}`;
};

export const hashPassword = (password) => {
  // const res = pbkdf2.pbkdf2Sync(password, PASSWORD_SALT, 4, 32, 'sha512');
  // return res.toString('hex');
  return password;
};

export const getLanguageCode = () => {
  let langCode = i18next.language;
  if (langCode === 'ch') {
    langCode = 'zh-CN'; // TEMP
  }

  return langCode;
};

export const getLocalisedString = (str, t) => {
  if (str && str.indexOf('__BR__') >= 0) {
    const strAr = str.split('__BR__');
    // NOTE: String manipulation done based on the number of occurrences of '__BR__'
    // This solution may need to change if and when the BEs support proper i18n support
    switch (strAr.length) {
      case 2:
        return t(strAr[0], { ref: strAr[1] });
      case 3:
        return t(strAr[0], { ref: strAr[1], var: strAr[2] });
      case 4:
        return t(strAr[0], { ref: strAr[1], var: strAr[2], var1: strAr[3] });
      default:
        return t(strAr[0]);
    }
  }
  return t(str);
};

export const getLocalisedMessageString = (str, t) => {
  if (str && str.indexOf('__BR__') >= 0) {
    const strAr = str.split('__BR__');
    // NOTE: String manipulation done based on the number of occurrences of '__BR__'
    // This solution may need to change if and when the BEs support proper i18n support
    switch (strAr.length) {
      case 2:
        return t(strAr[0], { ref: strAr[1] });
      case 3:
        return t(strAr[0], { ref: strAr[1], var: strAr[2] });
      case 4:
        return t(strAr[0], { ref: strAr[1], var: strAr[2], var1: strAr[3] });
      case 5:
        return t(strAr[0], {
          ref: strAr[1], var: strAr[2], var1: strAr[3], var2: strAr[4]
        });
      case 6:
        return t(strAr[0], {
          ref: strAr[1], var: strAr[2], var1: strAr[3], var2: strAr[4], var3: strAr[5]
        });
      default:
        return t(strAr[0]);
    }
  }
  return t(str);
};

export const getParameterValues = (param, values) => {
  switch (param) {
    case 'token':
      return values.token;
    case 'authCode':
      return values.authCode;
    case 'userRef':
      return values.userRef;
    case 'role':
      return values.role;
    case 'rememberMe':
      return values.rememberMe;
    case 'markettingPromotions':
      return values.markettingPromotions;
    case 'language':
      return values.language;
    default:
      return '';
  }
};

export const makePassword = () => {
  let password = '';
  const capps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const simps = 'abcdefghijklmnopqrstuvwxyz';
  const digis = '0123456789';
  const chars = '$*!_';
  for (let i = 0; i < 8; i += 1) {
    password += capps.charAt(Math.floor(Math.random() * capps.length));
    password += simps.charAt(Math.floor(Math.random() * simps.length));
    password += digis.charAt(Math.floor(Math.random() * digis.length));
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

export const appendContactSupport = (str, t, supportEmail) => {
  if (str) {
    if (str.includes(supportEmail)) {
      return str;
    }
    const lastChar = t(str).charAt(str.length - 1);
    const fullStop = lastChar !== '.' || lastChar !== '!' ? '.' : '';
    return `${t(str)}${fullStop} ${t('CONTACT_SUPPORT', { email: supportEmail })}`;
  }
  return `${t('SOMETHING_WENT_WRONG')} ${t('CONTACT_SUPPORT', { email: supportEmail })}`;
};
