import { createContext } from 'react';

const BrandContext = createContext({
  BRAND_NAME: 'Procys',
  BRAND_DOMAIN: 'procys.com',
  IS_BRAND_PROCYS: true,
  RECAPTCHA_SITE_KEY: '6Lf9WVMpAAAAAPMOTyJHRYwStBE_1VUAM3OclTuK'
});

export default BrandContext;
