import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../Shared/helpers';

const initialState = {
  entityFields: [],
  loading: false,
  error: null,
  message: null
};

const fetchEntityFieldsStart = (state) => {
  return updateObject(state, { loading: true });
};

const fetchEntityFieldsSuccess = (state, action) => {
  return updateObject(state, {
    entityFields: action.entityFields,
    loading: false,
    error: null
  });
};

const fetchEntityFieldsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error, entityFields: [] });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ENTITY_FIELDS_START: return fetchEntityFieldsStart(state);
    case actionTypes.FETCH_ENTITY_FIELDS_SUCCESS: return fetchEntityFieldsSuccess(state, action);
    case actionTypes.FETCH_ENTITY_FIELDS_FAIL: return fetchEntityFieldsFail(state, action);
    default: return state;
  }
};

export default reducer;
