import { put } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';

import * as actions from '../actions/entityFields';
import API from '../../../config';
import { appendContactSupport, axiosHeaders } from '../../../Shared/helpers';

const t = i18next.t.bind(i18next);

function* fetchEntityFieldsSaga(action) {
  yield put(actions.fetchEntityFieldsStart());
  const url = `${window.config.backend.provider_service}${API.entityFields}/${action.entity}/${action.company}`;
  try {
    const response = yield axios.get(url, axiosHeaders(action.token));
    yield put(actions.fetchEntityFieldsSuccess(response.data.data.fields));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }

    yield put(actions.fetchEntityFieldsFail(errorMessage));
  }
}

export default fetchEntityFieldsSaga;
