import * as actionTypes from './actionTypes';

export const signUpStart = () => {
  return {
    type: actionTypes.SIGN_UP_START
  };
};

export const signUpSuccess = (message) => {
  return {
    type: actionTypes.SIGN_UP_SUCCESS,
    message
  };
};

export const signUpFail = (error, path) => {
  return {
    type: actionTypes.SIGN_UP_FAIL,
    error,
    path
  };
};

export const signUp = (email, password, userType, marketingPromo) => {
  return {
    type: actionTypes.SIGN_UP_USER,
    email,
    password,
    userType,
    marketingPromo
  };
};

export const signUpResendStart = () => {
  return {
    type: actionTypes.SIGN_UP_RESEND_START
  };
};

export const signUpResendSuccess = (message) => {
  return {
    type: actionTypes.SIGN_UP_RESEND_SUCCESS,
    message
  };
};

export const signUpResendFail = (error, path) => {
  return {
    type: actionTypes.SIGN_UP_RESEND_FAIL,
    error,
    path
  };
};

export const signUpResend = (email, userType) => {
  return {
    type: actionTypes.SIGN_UP_RESEND_USER,
    email,
    userType
  };
};

export const emailConfirmationStart = () => {
  return {
    type: actionTypes.EMAIL_CONFIRMATION_START
  };
};

export const emailConfirmationSuccess = (message, company, application, password) => {
  return {
    type: actionTypes.EMAIL_CONFIRMATION_SUCCESS,
    message,
    company,
    application,
    password
  };
};

export const emailConfirmationFail = (error) => {
  return {
    type: actionTypes.EMAIL_CONFIRMATION_FAIL,
    error
  };
};

export const emailConfirmation = (token) => {
  return {
    type: actionTypes.EMAIL_CONFIRMATION,
    token
  };
};

export const registerStart = () => {
  return {
    type: actionTypes.REGISTER_START
  };
};

export const registerSuccess = (message, token, userRef, expiresIn, secret, qrText) => {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    message,
    token,
    userRef,
    expiresIn,
    secret,
    qrText
  };
};

export const registerFail = (error) => {
  return {
    type: actionTypes.REGISTER_FAIL,
    error
  };
};

export const register = (token, company, mlCompany, details, creator) => {
  return {
    type: actionTypes.REGISTER_USER,
    token,
    company,
    mlCompany,
    creator,
    details
  };
};

export const addUserEmailConfirmationStart = () => {
  return {
    type: actionTypes.ADD_USER_EMAIL_CONFIRMATION_START
  };
};

export const addUserEmailConfirmationSuccess = (message, company, application) => {
  return {
    type: actionTypes.ADD_USER_EMAIL_CONFIRMATION_SUCCESS,
    message,
    company,
    application
  };
};

export const addUserEmailConfirmationFail = (error) => {
  return {
    type: actionTypes.ADD_USER_EMAIL_CONFIRMATION_FAIL,
    error
  };
};

export const addUserEmailConfirmation = (token) => {
  return {
    type: actionTypes.ADD_USER_EMAIL_CONFIRMATION,
    token
  };
};

export const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START
  };
};

export const addUserSuccess = (message, token, userRef, expiresIn, secret, qrText) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    message,
    token,
    userRef,
    expiresIn,
    secret,
    qrText
  };
};

export const addUserFail = (error) => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error
  };
};

export const addUser = (token, password) => {
  return {
    type: actionTypes.ADD_USER,
    token,
    password
  };
};

export const signUpSocialMediaStart = () => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_START
  };
};

export const signUpSocialMediaSuccess = () => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_SUCCESS
  };
};

export const signUpSocialMediaFail = (error) => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_FAIL,
    error
  };
};

export const signUpSocialMedia = (userType) => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_USER,
    userType
  };
};

export const setSignUpRedirectPath = (path) => {
  return {
    type: actionTypes.SET_SIGN_UP_REDIRECT_PATH,
    path
  };
};

export const signUpSocialMediaCallbackStart = () => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_START
  };
};

export const signUpSocialMediaCallbackSuccess = (token, userRef, company) => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_SUCCESS,
    idToken: token,
    userRef,
    company
  };
};

export const signUpSocialMediaCallbackFail = (error) => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_FAIL,
    error
  };
};

export const signUpSocialMediaCallback = (platform, params) => {
  return {
    type: actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK,
    platform,
    params
  };
};

export const checkDisposableEmailStart = () => {
  return {
    type: actionTypes.CHECK_DISPOSABLE_EMAIL_START
  };
};

export const checkDisposableEmailSuccess = (disposable) => {
  return {
    type: actionTypes.CHECK_DISPOSABLE_EMAIL_SUCCESS,
    disposable
  };
};

export const checkDisposableEmailFail = (error) => {
  return {
    type: actionTypes.CHECK_DISPOSABLE_EMAIL_FAIL,
    error
  };
};

export const checkDisposableEmail = (email) => {
  return {
    type: actionTypes.CHECK_DISPOSABLE_EMAIL,
    email
  };
};

export const verifyVatNumberStart = () => {
  return {
    type: actionTypes.VERIFY_VAT_NUMBER_START
  };
};

export const verifyVatNumberSuccess = (company, registered) => {
  return {
    type: actionTypes.VERIFY_VAT_NUMBER_SUCCESS,
    company,
    registered
  };
};

export const verifyVatNumberFail = (error) => {
  return {
    type: actionTypes.VERIFY_VAT_NUMBER_FAIL,
    error
  };
};

export const verifyVatNumber = (token, vatNumber) => {
  return {
    type: actionTypes.VERIFY_VAT_NUMBER,
    token,
    vatNumber
  };
};

export const verifyCompanyNameStart = () => {
  return {
    type: actionTypes.VERIFY_COMPANY_NAME_START
  };
};

export const verifyCompanyNameSuccess = (company, registered) => {
  return {
    type: actionTypes.VERIFY_COMPANY_NAME_SUCCESS,
    company,
    registered
  };
};

export const verifyCompanyNameFail = (error) => {
  return {
    type: actionTypes.VERIFY_COMPANY_NAME_FAIL,
    error
  };
};

export const verifyCompanyName = (token, name) => {
  return {
    type: actionTypes.VERIFY_COMPANY_NAME,
    token,
    name
  };
};

export const addCompanyStart = () => {
  return {
    type: actionTypes.ADD_COMPANY_START
  };
};

export const addCompanySuccess = (message, company) => {
  return {
    type: actionTypes.ADD_COMPANY_SUCCESS,
    message,
    company
  };
};

export const addCompanyFail = (error) => {
  return {
    type: actionTypes.ADD_COMPANY_FAIL,
    error
  };
};

export const addCompany = (token, company) => {
  return {
    type: actionTypes.ADD_COMPANY,
    token,
    company
  };
};
