import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import fetchEntityFieldsSaga from './entityFields';
import fetchNewsSaga from './news';

export function* watchEntityFields() {
  yield takeEvery(actionTypes.FETCH_ENTITY_FIELDS, fetchEntityFieldsSaga);
}

export function* watchNews() {
  yield takeEvery(actionTypes.FETCH_NEWS, fetchNewsSaga);
}
