import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../Shared/helpers';
// import * as paths from '../../../Shared/PathNames';

const initialState = {
  message: null,
  email: null,
  error: null,
  companyError: null,
  loading: false,
  password: '',
  disposable: 0,
  company: null,
  application: null,
  companyFromVat: null,
  signUpRedirectPath: null,
  token: null,
  userRef: null,
  expiresIn: null,
  secret: null,
  qrText: null,
  companyRegistered: false,
  registeringUser: false,
  registeredUser: false,
  emailConfirmed: false
};

const signUpStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    signUpRedirectPath: action.path,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpResendStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: true,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpResendSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpResendFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    signUpRedirectPath: action.path,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const emailConfirmationStart = (state) => {
  return updateObject(state, {
    email: null,
    error: null,
    loading: true,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const emailConfirmationSuccess = (state, action) => {
  return updateObject(state, {
    message: null,
    email: action.message,
    company: action.company,
    application: action.application,
    emailConfirmed: true,
    password: action.password,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const emailConfirmationFail = (state, action) => {
  return updateObject(state, {
    email: null,
    error: action.error,
    loading: false,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const registerStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    registeringUser: true,
    registeredUser: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const registerSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    registeringUser: false,
    registeredUser: true,
    signUpRedirectPath: null,
    token: action.token,
    userRef: action.userRef,
    expiresIn: action.expiresIn,
    secret: action.secret,
    qrText: action.qrText,
    disposable: 0
  });
};

const registerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    registeringUser: false,
    registeredUser: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addUserConfirmationStart = (state) => {
  return updateObject(state, {
    email: null,
    error: null,
    loading: true,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addUserConfirmationSuccess = (state, action) => {
  return updateObject(state, {
    message: null,
    email: action.message,
    company: action.company,
    application: action.application,
    emailConfirmed: true,
    password: action.password,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addUserConfirmationFail = (state, action) => {
  return updateObject(state, {
    email: null,
    error: action.error,
    loading: false,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addUserStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addUserSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: action.token,
    userRef: action.userRef,
    expiresIn: action.expiresIn,
    secret: action.secret,
    qrText: action.qrText,
    disposable: 0
  });
};

const addUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSocialMediaStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSocialMediaSuccess = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSocialMediaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

export const setSignUpRedirectPath = (state, action) => {
  return updateObject(state, { signUpRedirectPath: action.path });
};

const signUpSocialMediaCallbackStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSocialMediaCallbackSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    message: action.message,
    company: action.company,
    signUpRedirectPath: null,
    token: action.idToken,
    userRef: action.userRef,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const signUpSocialMediaCallbackFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    message: null,
    company: null,
    authRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const checkDisposableEmailStart = (state) => {
  return updateObject(state, {
    disposable: 0,
    error: null,
    loading: true,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null
  });
};

const checkDisposableEmailSuccess = (state, action) => {
  return updateObject(state, {
    disposable: action.disposable,
    message: null,
    company: null,
    error: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null
  });
};

const checkDisposableEmailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    disposable: 0,
    loading: false,
    message: null,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null
  });
};

const verifyVatNumberStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    companyError: null,
    companyRegistered: false,
    loading: true,
    company: null,
    companyFromVat: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const verifyVatNumberSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    companyFromVat: action.company,
    companyRegistered: action.registeed,
    error: null,
    companyError: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const verifyVatNumberFail = (state, action) => {
  return updateObject(state, {
    companyError: action.error,
    companyFromVat: null,
    error: null,
    loading: false,
    message: null,
    signUpRedirectPath: action.path,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const verifyCompanyNameStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    companyError: null,
    companyRegistered: false,
    loading: true,
    company: null,
    companyFromVat: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const verifyCompanyNameSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    companyFromVat: action.company,
    companyRegistered: action.registeed,
    error: null,
    companyError: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const verifyCompanyNameFail = (state, action) => {
  return updateObject(state, {
    companyError: action.error,
    companyFromVat: null,
    error: null,
    loading: false,
    message: null,
    signUpRedirectPath: action.path,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addCompanyStart = (state) => {
  return updateObject(state, {
    message: null,
    error: null,
    companyError: null,
    companyRegistered: false,
    loading: true,
    company: null,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addCompanySuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    companyFromVat: action.company,
    companyRegistered: true,
    error: null,
    companyError: null,
    loading: false,
    signUpRedirectPath: null,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const addCompanyFail = (state, action) => {
  return updateObject(state, {
    companyError: action.error,
    error: null,
    loading: false,
    companyRegistered: false,
    message: null,
    signUpRedirectPath: action.path,
    token: null,
    userRef: null,
    expiresIn: null,
    secret: null,
    qrText: null,
    disposable: 0
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_START: return signUpStart(state, action);
    case actionTypes.SIGN_UP_SUCCESS: return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAIL: return signUpFail(state, action);
    case actionTypes.SIGN_UP_RESEND_START: return signUpResendStart(state, action);
    case actionTypes.SIGN_UP_RESEND_SUCCESS: return signUpResendSuccess(state, action);
    case actionTypes.SIGN_UP_RESEND_FAIL: return signUpResendFail(state, action);
    case actionTypes.EMAIL_CONFIRMATION_START: return emailConfirmationStart(state, action);
    case actionTypes.EMAIL_CONFIRMATION_SUCCESS: return emailConfirmationSuccess(state, action);
    case actionTypes.EMAIL_CONFIRMATION_FAIL: return emailConfirmationFail(state, action);
    case actionTypes.REGISTER_START: return registerStart(state, action);
    case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
    case actionTypes.REGISTER_FAIL: return registerFail(state, action);
    case actionTypes.ADD_USER_EMAIL_CONFIRMATION_START: return addUserConfirmationStart(state, action);
    case actionTypes.ADD_USER_EMAIL_CONFIRMATION_SUCCESS: return addUserConfirmationSuccess(state, action);
    case actionTypes.ADD_USER_EMAIL_CONFIRMATION_FAIL: return addUserConfirmationFail(state, action);
    case actionTypes.ADD_USER_START: return addUserStart(state, action);
    case actionTypes.ADD_USER_SUCCESS: return addUserSuccess(state, action);
    case actionTypes.ADD_USER_FAIL: return addUserFail(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_START: return signUpSocialMediaStart(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_SUCCESS:
      return signUpSocialMediaSuccess(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_FAIL: return signUpSocialMediaFail(state, action);
    case actionTypes.SET_SIGN_UP_REDIRECT_PATH: return setSignUpRedirectPath(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_START:
      return signUpSocialMediaCallbackStart(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_SUCCESS:
      return signUpSocialMediaCallbackSuccess(state, action);
    case actionTypes.SIGN_UP_SOCIAL_MEDIA_CALLBACK_FAIL:
      return signUpSocialMediaCallbackFail(state, action);
    case actionTypes.CHECK_DISPOSABLE_EMAIL_START: return checkDisposableEmailStart(state, action);
    case actionTypes.CHECK_DISPOSABLE_EMAIL_SUCCESS: return checkDisposableEmailSuccess(state, action);
    case actionTypes.CHECK_DISPOSABLE_EMAIL_FAIL: return checkDisposableEmailFail(state, action);
    case actionTypes.VERIFY_VAT_NUMBER_START: return verifyVatNumberStart(state, action);
    case actionTypes.VERIFY_VAT_NUMBER_SUCCESS: return verifyVatNumberSuccess(state, action);
    case actionTypes.VERIFY_VAT_NUMBER_FAIL: return verifyVatNumberFail(state, action);
    case actionTypes.VERIFY_COMPANY_NAME_START: return verifyCompanyNameStart(state, action);
    case actionTypes.VERIFY_COMPANY_NAME_SUCCESS: return verifyCompanyNameSuccess(state, action);
    case actionTypes.VERIFY_COMPANY_NAME_FAIL: return verifyCompanyNameFail(state, action);
    case actionTypes.ADD_COMPANY_START: return addCompanyStart(state, action);
    case actionTypes.ADD_COMPANY_SUCCESS: return addCompanySuccess(state, action);
    case actionTypes.ADD_COMPANY_FAIL: return addCompanyFail(state, action);
    default:
      return state;
  }
};

export default reducer;
