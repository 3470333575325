import { put } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';

import * as actions from '../actions/signUp';
import * as authActions from '../actions/auth';
import * as entityFieldActions from '../../../SuperUser/store/actions/entityFields';
import API from '../../../config';
import { appendContactSupport, axiosHeaders } from '../../../Shared/helpers';
import { FORM_PROPS } from '../../../Shared/config';

const t = i18next.t.bind(i18next);

export function* signUpUserSaga(action) {
  yield put(actions.signUpStart());
  const authData = {
    email: action.email,
    password: action.password,
    type: action.userType,
    application: 'procys',
    marketingPromo: action.marketingPromo
  };

  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.signUp}`, authData);
    yield put(actions.signUpSuccess(response.data.message));
  } catch (error) {
    let errorMessage = null;
    let url = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
      if (error.response.data.error.code === 302) {
        errorMessage = 'User is being redirected to details registration.';
        url = error.response.data.message;
      }
    }
    yield put(actions.signUpFail(errorMessage, url));
  }
}

export function* signUpEmailResendSaga(action) {
  yield put(actions.signUpResendStart());
  const authData = {
    email: action.email,
    type: action.userType,
    application: 'procys'
  };

  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.signUpResend}`, authData);
    yield put(actions.signUpResendSuccess(response.data.message));
  } catch (error) {
    let errorMessage = null;
    let url = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
      if (error.response.data.error.code === 302) {
        errorMessage = 'User is being redirected to details registration.';
        url = error.response.data.message;
      }
    }
    yield put(actions.signUpResendFail(errorMessage, url));
  }
}

export function* emailConfirmationSaga(action) {
  yield put(actions.emailConfirmationStart());
  try {
    const url = `${window.config.backend.login_service}${API.emailConfirmation}/${action.token}`;
    const response = yield axios.get(url);
    const chunks = response.data.data.email.split('@').join('.').split('.');
    const company = chunks[1];
    // NOTE: The use of Company here?? Send user company via response??
    // We need to prioritise company or application at some stage, at that time, this needs to be fixed
    yield put(actions.emailConfirmationSuccess(
      response.data.data.email,
      company,
      response.data.data.application,
      response.data.data.password
    ));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.emailConfirmationFail(errorMessage));
  }
}

export function* registerSaga(action) {
  yield put(actions.registerStart());

  const registerData = {
    company: action.mlCompany.name,
    creator: action.creator,
    details: action.details
  };

  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.register}`, registerData, axiosHeaders(action.token));
    yield localStorage.setItem('token', response.data.data.accessToken);
    yield localStorage.setItem('secret', response.data.data.secret);
    yield localStorage.setItem('qrText', response.data.data.qrText);
    yield put(actions.registerSuccess(
      response.data.message,
      response.data.data.accessToken,
      response.data.data.userRef,
      response.data.data.expiresIn,
      response.data.data.secret,
      response.data.data.qrText
    ));
    yield put(authActions.registerTwoFA(response.data.data.accessToken, '', false));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.registerFail(errorMessage));
  }
}

export function* addUserEmailConfirmationSaga(action) {
  yield put(actions.addUserEmailConfirmationStart());
  try {
    const url = `${window.config.backend.login_service}${API.emailConfirmation}/${action.token}`;
    const response = yield axios.get(url);
    yield put(actions.addUserEmailConfirmationSuccess(
      response.data.data.email,
      '',
      response.data.data.application,
      response.data.data.password
    ));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.addUserEmailConfirmationFail(errorMessage));
  }
}

export function* addUserSaga(action) {
  yield put(actions.addUserStart());

  const registerData = {
    password: action.password
  };

  try {
    const response = yield axios.post(
      `${window.config.backend.login_service}${API.userRegister}`,
      registerData, axiosHeaders(action.token)
    );
    yield localStorage.setItem('token', response.data.data.accessToken);
    yield localStorage.setItem('secret', response.data.data.secret);
    yield localStorage.setItem('qrText', response.data.data.qrText);
    yield put(authActions.authSuccess(
      response.data.data.accessToken,
      response.data.data.userRef,
      response.data.data.userType,
      response.data.data.authCode,
      false
    ));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.registerFail(errorMessage));
  }
}

export function* signUpSocialMediaUserSaga(action) {
  yield put(actions.signUpSocialMediaStart());
  const signUpData = {
    type: action.userType,
    application: 'procys'
  };
  try {
    const response = yield axios.post(`${window.config.backend.login_service}${API.signUp}`, signUpData);
    yield put(actions.setSignUpRedirectPath(response.data.message));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.signUpSocialMediaFail(errorMessage));
  }
}

export function* signUpSocialMediaCallbackSaga(action) {
  yield put(actions.signUpSocialMediaCallbackStart());
  try {
    const url = `${window.config.backend.login_service}${API.socialMediaCallback}/${action.platform}-signup-cb?${action.params}`;
    const response = yield axios.get(url);
    yield put(entityFieldActions.fetchEntityFields(
      response.data.data.accessToken,
      FORM_PROPS.form_type_user_details_name,
      response.data.data.company
    ));
    yield put(actions.signUpSocialMediaCallbackSuccess(
      response.data.data.accessToken,
      response.data.data.userRef,
      response.data.data.company
    ));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.signUpSocialMediaCallbackFail(errorMessage));
  }
}

export function* checkDisposableEmailSaga(action) {
  yield put(actions.checkDisposableEmailStart());
  try {
    const url = `${API.checkDisposableEmail}/?email=${action.email}`;
    const response = yield axios.get(url);
    const disposable = response.data.disposable === 'true' ? 1 : 2;
    yield put(actions.checkDisposableEmailSuccess(disposable));
  } catch (error) {
    yield put(actions.checkDisposableEmailFail('Error occurred while checking for disposable email'));
  }
}

export function* verifyVatNumberSaga(action) {
  yield put(actions.verifyVatNumberStart());
  try {
    let errorMessage = null;
    const url = `${window.config.backend.provider_service}${API.verifyVatNumber}/${action.vatNumber}`;
    const response = yield axios.get(url, axiosHeaders(action.token));
    if (response.data.data.availableUserCount <= 0
      || response.data.data.availableUserCount === undefined) {
      errorMessage = 'User creation count exceeded for your company. Please upgrade your subscription';
      yield put(actions.verifyVatNumberFail(errorMessage));
      return;
    }
    yield put(actions.verifyVatNumberSuccess({
      vatNumber: action.vatNumber,
      application: 'procys',
      name: response.data.data.name,
      city: response.data.data.city,
      address: response.data.data.address,
      phone: response.data.data.phone,
      firstname: response.data.data.firstname,
      lastname: response.data.data.lastname,
      email: response.data.data.email,
      password: response.data.data.password,
      postalCode: response.data.data.postalCode
    }, true));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.verifyVatNumberFail(errorMessage));
  }
}

export function* verifyCompanyNameSaga(action) {
  yield put(actions.verifyCompanyNameStart());
  try {
    let errorMessage = null;
    const url = `${window.config.backend.provider_service}${API.verifyCompanyName}/${encodeURIComponent(action.name)}`;
    const response = yield axios.get(url, axiosHeaders(action.token));
    if (response.data.data.availableUserCount <= 0
      || response.data.data.availableUserCount === undefined) {
      errorMessage = 'User creation count exceeded for your company. Please upgrade your subscription';
      yield put(actions.verifyCompanyNameFail(errorMessage));
      return;
    }
    yield put(actions.verifyCompanyNameSuccess({
      vatNumber: response.data.data.vatNumber,
      application: 'procys',
      name: response.data.data.name,
      city: response.data.data.city,
      address: response.data.data.address,
      phone: response.data.data.phone,
      firstname: response.data.data.firstname,
      lastname: response.data.data.lastname,
      email: response.data.data.email,
      password: response.data.data.password,
      postalCode: response.data.data.postalCode
    }, true));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.verifyCompanyNameFail(errorMessage));
  }
}

export function* addCompanySaga(action) {
  yield put(actions.addCompanyStart());
  try {
    const url = `${window.config.backend.provider_service}${API.addCompanies}`;
    const response = yield axios.post(url, action.company, axiosHeaders(action.token));
    yield put(actions.addCompanySuccess(response.data.message, action.company));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = appendContactSupport(error.message, t, window.config.support_email);
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.addCompanyFail(errorMessage));
  }
}
