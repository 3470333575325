import * as actionTypes from './actionTypes';

export const fetchEntityFieldsSuccess = (entityFields) => {
  return {
    type: actionTypes.FETCH_ENTITY_FIELDS_SUCCESS,
    entityFields
  };
};

export const fetchEntityFieldsFail = (error) => {
  return {
    type: actionTypes.FETCH_ENTITY_FIELDS_FAIL,
    error
  };
};

export const fetchEntityFieldsStart = () => {
  return {
    type: actionTypes.FETCH_ENTITY_FIELDS_START
  };
};

export const fetchEntityFields = (token, entity, company) => {
  return {
    type: actionTypes.FETCH_ENTITY_FIELDS,
    token,
    entity,
    company
  };
};
